import { Dropdown } from '@flipgrid/flipkit';
import { Link as RemixLink } from '@remix-run/react';

import type { LinkProps as FkLinkProps } from '@flipgrid/flipkit';
import type { LinkProps as RemixLinkProps } from '@remix-run/react';

const DropdownLink = ({ children, ...rest }: FkLinkProps & RemixLinkProps) => {
  return (
    <Dropdown.Item as={RemixLink} {...rest}>
      {children || null}
    </Dropdown.Item>
  );
};

export default DropdownLink;
