import i18next, { t } from 'i18next';
import { isNil, trim, union } from 'lodash-es';
import Papa from 'papaparse';

import type { Member, Username } from 'types';

export const USER_ERRORS = () => {
  return {
    FIRST_NAME_REQUIRED: t('usernameCSVUpload.firstNameRequired'),
    LAST_NAME_REQUIRED: t('usernameCSVUpload.lastNameRequired'),
    USERNAME_OVERLAP: t('usernameCSVUpload.usernameOverlap'),
    USERNAME_TOO_SHORT: t('usernameCSVUpload.usernameTooShort'),
  };
};

function isPresent(value: string) {
  return !(isNil(value) || trim(value) === '');
}

export function validateUser(user: Username & { id?: number }, allUsers = []) {
  const errors = {} as Username;
  let users = [...allUsers];
  if (user.id) users = users.filter((s: Member) => s.id !== user.id);
  // User username must be at least 2 characters long. Username left empty will be autogenerated.
  if (!isPresent(user.uid) || user.uid.length <= 1) {
    errors.uid = USER_ERRORS().USERNAME_TOO_SHORT;
  } else if (users.find((s: Member) => s.uid === user.uid)) {
    errors.uid = USER_ERRORS().USERNAME_OVERLAP;
  }

  // Required fields
  if (!isPresent(user.first_name)) {
    errors.first_name = USER_ERRORS().FIRST_NAME_REQUIRED;
  }
  if (!isPresent(user.last_name)) {
    errors.last_name = USER_ERRORS().LAST_NAME_REQUIRED;
  }

  return errors;
}

export function parseUserCSV(file: File) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      complete: ({ data: students, errors }) => {
        if (errors.length === 0 && students) {
          return resolve(students);
        }
        return reject(errors);
      },
    });
  });
}

export function formatCSVUploadErrors(errors: TSFix) {
  const errorChunks = { mainError: '', errorDetails: '' };
  if (!errors) return null;
  if (errors.invalidCSV) {
    errorChunks.mainError = i18next.t('usernameListHelpers.invalidCSV');
  } else if (errors.invalidColumns.length > 0) {
    errorChunks.mainError = i18next.t('shared.missingCSVColumnError', {
      missingColumns: `"${errors.invalidColumns.join('", "')}"`,
      count: errors.invalidColumns.length,
    });
  } else {
    const errorDetails = [];
    if (errors.missingFirst.length > 0) {
      errorDetails.push(
        i18next.t('usernameListHelpers.invalidColumns', {
          count: errors.missingFirst.length,
          listOfInvalidRecords: errors.missingFirst.join(', '),
          columnName: 'first_name',
        }),
      );
    }
    if (errors.missingLast.length > 0) {
      errorDetails.push(
        i18next.t('usernameListHelpers.invalidColumns', {
          count: errors.missingLast.length,
          listOfInvalidRecords: errors.missingLast.join(', '),
          columnName: 'last_name',
        }),
      );
    }
    if (errors.invalidUsername.length > 0) {
      errorDetails.push(
        i18next.t('usernameListHelpers.invalidColumns', {
          count: errors.invalidUsername.length,
          listOfInvalidRecords: errors.invalidUsername.join(', '),
          columnName: 'id',
        }),
      );
    }
    if (errors.duplicateUsername.length > 0) {
      errorDetails.push(
        i18next.t('usernameListHelpers.duplicateUsername', {
          count: errors.duplicateUsername.length,
          listOfInvalidRecords: errors.duplicateUsername.join(', '),
        }),
      );
    }
    if (errorDetails.length) {
      errorChunks.errorDetails = errorDetails.join(' ');
      errorChunks.mainError = i18next.t('usernameListHelpers.errorSummary', {
        count: union(errors.missingFirst, errors.missingLast, errors.invalidUsername, errors.duplicateUsername).length,
      });
    }
  }

  if (errorChunks.mainError) {
    return errorChunks;
  }

  return null;
}
