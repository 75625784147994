import { useFetchers } from '@remix-run/react';
import { useEffect, useRef } from 'react';

import type { Fetcher } from '@remix-run/react';
import type { RouteType, ResourceRouteType } from 'types';

type Props<T> = {
  action: RouteType | ResourceRouteType | (RouteType | ResourceRouteType)[]; // string matching the fetcher.submission.action you're targeting
  formDataAction?: string | string[]; // _action string you're targeting
  updateCondition?: boolean; // additional condition to check for before updating
  onError?: () => void; // function to run on error
} & (
  | {
      update: (data?: T) => void; // function to run your local updates
      optimisticUpdate?: never; // function to run your optimistic updates
    }
  | {
      update?: never; // function to run your local updates
      optimisticUpdate: (data?: Fetcher<T>) => void; // function to run your optimistic updates
    }
);

function useFetcherWatcher<T>({
  action,
  update,
  formDataAction,
  updateCondition = true,
  optimisticUpdate,
  onError,
}: Props<T>) {
  const fetchers = useFetchers();
  const foundFetcherIndex = useRef<null | number>(null);
  const didUpdateOptimistically = useRef(false);

  const fetcherToFind = fetchers.find(f => {
    const fetcherAction = f.submission?.formData ? f.submission?.formData.get('_action') : undefined;
    return (
      f?.submission?.action &&
      (action as string | string[]).includes(f.submission.action) &&
      (formDataAction ? fetcherAction && formDataAction.includes(fetcherAction?.toString()) : true)
    );
  });

  if (fetcherToFind) {
    if (optimisticUpdate && updateCondition) {
      if (!didUpdateOptimistically.current) {
        optimisticUpdate(fetcherToFind);
        didUpdateOptimistically.current = true;
      }
    }
    foundFetcherIndex.current = fetchers.indexOf(fetcherToFind);
  }

  useEffect(() => {
    if (
      typeof foundFetcherIndex.current === 'number' &&
      fetchers[foundFetcherIndex.current]?.type === 'done' &&
      updateCondition
    ) {
      if (!optimisticUpdate) {
        if (
          !fetchers[foundFetcherIndex.current].data?.error ||
          Object.keys(fetchers[foundFetcherIndex.current].data?.error).length === 0
        ) {
          update(fetchers[foundFetcherIndex.current].data);
        } else if (onError) {
          onError();
        }
      }
      didUpdateOptimistically.current = false;
      foundFetcherIndex.current = null;
    }
  }, [fetchers, updateCondition, update, onError, optimisticUpdate]);
}

export default useFetcherWatcher;
