import { Button, ButtonSubmit, IcFluentChevronRight24Regular, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Radio from '../FkWrappers/Radio';
import externalLinks from '~/constants/externalLinks';
import resourceRoutes from '~/constants/resourceRoutes';
import reportModalStyles from '~/styles/components/Report/ReportModal.css';

import type { ApiResponse, Group } from '../../../types';
import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: reportModalStyles }];

enum ReportView {
  Category,
  SubCategory,
  FinalSubmission,
  ReceivedReport,
}

type Props = {
  onRequestClose: OnRequestCloseType;
} & (
{
  // TODO: Finish updating this flow?
  contentOwnerDisplayName?: never;
  contentName: string;
  contentType: 'topicTemplate';
  id: number;
  gridId?: never;
  responseId?: never;
  topicId?: never;
} |
{
  contentOwnerDisplayName: string;
  contentName?: never;
  contentType: 'comment';
  id?: never;
  gridId: number;
  responseId: number;
  topicId: number;
} |
{
  contentOwnerDisplayName?: never;
  contentName: string;
  contentType: 'group';
  id?: never;
  gridId: number;
  responseId?: never;
  topicId?: never;
} |
{
  contentOwnerDisplayName: string;
  contentName?: never;
  contentType: 'response';
  id?: never;
  gridId: number;
  responseId: number;
  topicId: number;
} |
{
  contentOwnerDisplayName?: never;
  contentName: string;
  contentType: 'topic';
  id?: never;
  gridId: number;
  responseId?: never;
  topicId: number;
});


const ReportModal = ({
  contentOwnerDisplayName,
  contentName,
  contentType,
  gridId = 0,
  onRequestClose,
  responseId,
  topicId = 0
}: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<ApiResponse<Group>>();
  const [reportView, setReportView] = useState(ReportView.Category);
  const [radioSelection, setRadioSelection] = useState('');
  const [categorySelection, setCategorySelection] = useState('');
  const newCats = {
    thisIsOffensive: ['bloodOrGore', 'falseInformation', 'hateSpeech', 'nudity', 'profanity', 'violence'],
    thisIsDangerousOrSuspicious: [
    'exploitationOrAbuse',
    'impersonation',
    'invasionOfPrivacy',
    'pornography',
    'terrorism',
    'threatingBehavior'],

    imWorriedAboutSomeone: ['bullying', 'exploitationOrAbuse', 'invasionOfPrivacy', 'selfHarm']
  };

  const resourceName = (): string => {
    let resource = '';
    if (contentType === 'response') {
      resource = t('common.video').toLowerCase();
    } else if (contentType === 'topic') {
      resource = t('common.topic').toLowerCase();
    } else if (contentType === 'group') {
      resource = t('common.group').toLowerCase();
    } else if (contentType === 'comment') {
      resource = t('common.comment').toLowerCase();
    }

    return resource;
  };

  const next = (e: React.FormEvent) => {
    e.preventDefault();
    setReportView(ReportView.SubCategory);
  };

  const goBack = (e: React.FormEvent) => {
    e.preventDefault();
    setReportView(ReportView.Category);
  };

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('reported_trait', radioSelection);
    formData.append('_action', contentType);
    formData.append('gridId', gridId?.toString() ?? '');
    formData.append('responseId', responseId?.toString() ?? '');
    formData.append('topicId', topicId?.toString() ?? '');
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.report });
  };

  useEffect(() => {
    if (fetcher.type === 'done') {
      setReportView(ReportView.FinalSubmission);
    }
  }, [fetcher]);

  function subCategoryView() {
    return (
      <Modal onClose={onRequestClose} className="reportModal">
        <div className="fk-blockRadio__container mt1" role="radiogroup" aria-labelledby="reportModal-report-what-title">
          <h1 id="reportModal-report-what-title" className="fk-modalHeader">
            {t('reportModal.describeWhatHappened')}
          </h1>
          {newCats[(categorySelection as keyof typeof newCats)].map((subCategory, index) => {
            // Translation Key Evaluations
            // reportModal.bloodOrGore
            // reportModal.bullying
            // reportModal.exploitationOrAbuse
            // reportModal.falseInformation
            // reportModal.hateSpeech
            // reportModal.impersonation
            // reportModal.invasionOfPrivacy
            // reportModal.nudity
            // reportModal.pornography
            // reportModal.profanity
            // reportModal.selfHarm
            // reportModal.terrorism
            // reportModal.threatingBehavior
            // reportModal.violence
            const categoryTransKey = `reportModal.${subCategory}`;
            return (
              <Radio
                key={subCategory + index}
                name={categorySelection}
                label={t((categoryTransKey as TSFix))}
                // aria-labelledby="reportModal-report-what-title"
                role="radio"
                theme="blockRight"
                selected={radioSelection === subCategory}
                size="default"
                onChange={() => {
                  setRadioSelection(subCategory);
                }} />);


          })}
        </div>
        <Modal.Actions className="mt2">
          <Button data-testid="reportModal__button__reportGrid" onClick={goBack} theme="secondary">
            {t('common.back')}
          </Button>
          <ButtonSubmit
            data-testid="reportModal__button__reportGrid"
            disabled={!radioSelection}
            onClick={onSubmit}
            loading={fetcher.state !== 'idle'}>

            {t('common.report')}
          </ButtonSubmit>
        </Modal.Actions>
      </Modal>);

  }

  function categoryView() {
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('reportModal.reportResource', { resourceName: resourceName() })}</h1>
        <p className="fk-modalBody m0">
          {contentType === 'comment' || contentType === 'response' ?
          <Trans i18nKey="reportModal.youAreReportingResponse" values={{ userDisplayName: contentOwnerDisplayName }}>
              You're reporting <b>{contentOwnerDisplayName}'s</b> {{ contentType: resourceName() }}.
            </Trans> :

          <Trans i18nKey="reportModal.youAreReportingGroup">
              You're reporting the <b>{{ name: contentName }}</b> {{ contentType: resourceName() }}.
            </Trans>}

        </p>
        <h2 id="reportModal-report-content-title" className="fk-h3">
          {t('reportModal.whyDoYouWantToReportThisContent')}
        </h2>
        <div className="fk-blockRadio__container">
          {Object.keys(newCats).map((category, index) => {
            return (
              <Button
                key={category + index}
                theme="secondary"
                aria-labelledby="reportModal-report-content-title"
                role="group"
                variant="block"
                data-testid={`reportModal__button__${category}`}
                className="reportModal__firstScreen__categoryButton"
                iconRight={<IcFluentChevronRight24Regular />}
                onClick={(e: React.FormEvent) => {
                  setCategorySelection(category);
                  next(e);
                }}>

                {
                  // Translation Key Evaluations
                  // reportModal.imWorriedAboutSomeone
                  // reportModal.thisIsDangerousOrSuspicious
                  // reportModal.thisIsOffensive
                }
                {t((`reportModal.${category}` as TSFix))}
              </Button>);

          })}
        </div>
        <Modal.Actions className="mt2">
          <Button data-testid="reportModal__button__reportGrid" onClick={onRequestClose} theme="secondary">
            {t('common.cancel')}
          </Button>
        </Modal.Actions>
      </Modal>);

  }

  function finalScreen(translationKey: string) {
    return fetcher?.data?.error && (Object.keys(fetcher?.data?.error) || [])?.length > 0 ?
    ErrorScreen() :
    thankYouScreen(translationKey);
  }

  function ErrorScreen() {
    return (
      <Modal onClose={onRequestClose} className="reportModal__errorReport">
        <h1 className="fk-modalHeader">{t('common.error')}</h1>
        <p className="reportModal__errorText">{t('reportModal.reportError')}</p>
        <Modal.Actions className="reportModal__errorModalActions">
          <Button
            data-testid="reportModal__button__reportGrid"
            onClick={goBack}
            className="reportModal__tryAgainButton"
            theme="secondary">

            {t('shared.tryAgain')}
          </Button>
          <ButtonSubmit
            data-testid="reportModal__button__reportGrid"
            className="reportModal__errorButton"
            onClick={onRequestClose}>

            {t('common.cancel')}
          </ButtonSubmit>
        </Modal.Actions>
      </Modal>);

  }

  function thankYouScreen(translationKey: string) {
    return (
      <Modal onClose={onRequestClose} className="reportModal__submittedReport">
        <img src={externalLinks.ClappingHandsEmoji} alt={t('reportModal.clappingHands')} width="100" height="100" />
        <h1 className="fk-h1-heavy">{t('reportModal.thankYou')}</h1>
        <p className="fk-m0 text-center">{t((translationKey as TSFix))}</p>
      </Modal>);

  }

  switch (reportView) {
    case ReportView.Category:
      return categoryView();
    case ReportView.SubCategory:
      return subCategoryView();
    case ReportView.FinalSubmission:
      return finalScreen('reportModal.youAreHelpingUs');
    case ReportView.ReceivedReport:
      return finalScreen('reportModal.weHaveReceivedYourReport');
    default:
      return categoryView();
  }
};

export default ReportModal;