import { createContext } from 'react';

import type { GroupAccessControls } from 'types';

type ShareGroupModalContextTypes = {
  accessControl: GroupAccessControls | undefined;
  setAccessControl: (val: GroupAccessControls) => void;
  setVanityToken: (val: string) => void;
  vanityToken: string | undefined;
};

const initialShareGroupModalContextValues = {
  accessControl: undefined,
  setAccessControl: () => {},
  setVanityToken: () => {},
  vanityToken: undefined,
};

export default createContext(initialShareGroupModalContextValues as ShareGroupModalContextTypes);
