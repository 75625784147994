import { Button } from '@flipgrid/flipkit';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import vanityTokenStatusStyles from '~/styles/components/Share/VanityTokenStatus.css';

import type { FormEvent } from 'react';

export const links = () => [{ rel: 'stylesheet', href: vanityTokenStatusStyles }];

type Props = {
  getTokenErrors: () => {
    isDuplicateToken: boolean | undefined;
    isInappropriateToken: boolean | undefined;
    isInvalidCharacters: boolean | undefined;
    isInvalidLength: boolean | undefined;
  } | null;
  hasVanityTokenUpdated: boolean;
  isEditMode: boolean;
  submitToken: (e: FormEvent<HTMLFormElement>) => void;
  validationState: string;
};

const VanityTokenStatus = ({
  getTokenErrors,
  hasVanityTokenUpdated,
  isEditMode,
  submitToken,
  validationState
}: Props) => {
  const { t } = useTranslation();

  const [statusData, setStatusData] = useState({
    fontColor: 'color__fg-2',
    text: t('linkSettings.deactivateCreateNewLink')
  });

  useEffect(() => {
    let updatedFontColor = 'color__fg-2';
    let updatedText = t('linkSettings.deactivateCreateNewLink');

    if (isEditMode) {
      const errors = getTokenErrors();
      if (
      errors?.isDuplicateToken ||
      errors?.isInappropriateToken ||
      errors?.isInvalidCharacters ||
      errors?.isInvalidLength)
      {
        updatedFontColor = 'color__error';
        if (errors?.isInvalidCharacters) updatedText = t('vanityTokenStatus.linkNoSpecialCharacters');
        if (errors?.isInvalidLength) updatedText = t('vanityTokenStatus.pleaseEnterMinimumFourCharacters');
        if (errors?.isDuplicateToken) updatedText = t('vanityTokenStatus.linkHasBeenTaken');
        if (errors?.isInappropriateToken) updatedText = t('vanityTokenStatus.inappropriateToken');
      }
    }

    if (hasVanityTokenUpdated) {
      updatedFontColor = 'color__lead';
      updatedText = t('shared.inviteLinkUpdated');
    }

    setStatusData({ fontColor: updatedFontColor, text: updatedText });
  }, [getTokenErrors, hasVanityTokenUpdated, isEditMode, t, validationState]);

  return (
    <div className="vanityTokenStatus fk-helpText">
      <p className={`m0 ${statusData.fontColor}`}>{statusData.text}</p>
      <div role="status">
        {
        /* This is a quick fix because the FlipKit component for helpTextBottom is not accessible in FlipApp... but the screen reader will correctly read the content here because it is in the active DOM. */
        /* Follow-up: Find out why "blank" is being announced after the status text content.  */
        hasVanityTokenUpdated ? t('shared.inviteLinkUpdated') : null}

      </div>
      {!hasVanityTokenUpdated &&
      <Button variant="text" onClick={submitToken} data-testid="vanityTokenStatus__button__refreshLink">
          {t('shared.refreshLink')}
        </Button>}

    </div>);

};

export default VanityTokenStatus;