import { Tooltip, Button } from '@flipgrid/flipkit';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useTooltipTrigger from '~/hooks/useTooltipTrigger';

type Props = {
  copiedURL: boolean;
  onCopied: () => void;
  text: string;
};

const CopyButtonTooltip = ({ copiedURL, onCopied, text }: Props) => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  useTooltipTrigger(copiedURL, buttonRef);

  const tooltipLabel = copiedURL ? t('shared.linkCopied') : t('common.copyLink');
  const showTooltip = copiedURL ? { show: copiedURL } : {}; // fixes issue where tooltip will not show on hover

  return (
    <>
      <Tooltip label={tooltipLabel} {...showTooltip}>
        <Button
          aria-label={t('shared.copyLinkUrl', { url: text })}
          onClick={onCopied}
          size="36"
          data-testid="copyButtonTooltip__button__copyToClipboard"
          ref={buttonRef}
        >
          {copiedURL ? t('common.copied') : t('common.copy')}
        </Button>
      </Tooltip>
    </>
  );
};

export default CopyButtonTooltip;
