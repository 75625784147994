import { Button, Modal } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  onContinue: () => void;
  onRequestClose: OnRequestCloseType;
};

const ConfirmViewOnlyTopicModal = ({ onContinue, onRequestClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal show onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('confirmViewOnlyTopicModal.makeViewOnly')}</h1>
      <p className="fk-modalBody">{t('confirmViewOnlyTopicModal.makeViewOnlyBody')}</p>
      <Modal.Actions>
        <Button theme="secondary" onClick={onRequestClose} data-testid="confirmViewOnlyModal__button__cancel">
          {t('common.cancel')}
        </Button>
        <Button
          theme="primary"
          onClick={() => {
            onContinue();
            onRequestClose();
          }}
          data-testid="confirmViewOnlyModal__button__delete"
        >
          {t('shared.yesThatsOk')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmViewOnlyTopicModal;
