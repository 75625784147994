import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import useFetcherWatcher from '~/hooks/useFetcherWatcher';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, Group } from 'types';

type Props = {
  onArchival: (archivedGroup: Group) => void;
  onRequestClose: OnRequestCloseType;
  toggleState: (arg0: 'hidden') => void;
};

const ArchiveGroupModal = ({ onArchival, onRequestClose, toggleState }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useFetcherWatcher<ApiResponse<Group>>({
    action: resourceRoutes.updateState,
    formDataAction: 'updateGroup',
    update: archivedGroup => {
      if (archivedGroup && archivedGroup.data?.id) {
        onArchival(archivedGroup.data);
      }
      onRequestClose(t('archiveGroupModal.groupHasBeenArchived'));
    },
  });

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader m0">{t('archiveGroupModal.archiveThisGroup')}</h1>
      <div className="fk-modalBody">
        <p>{t('archiveGroupModal.studentsWillNoLonger')}</p>
      </div>
      <Modal.Actions className="mt2">
        <Button theme="secondary" onClick={onRequestClose} data-testid="archiveGroupModal__button__cancel">
          {t('common.cancel')}
        </Button>
        <ButtonSubmit
          name="_action"
          value="archiveGroup"
          loading={loading}
          data-testid="archiveGroupModal__button__archive"
          onClick={() => {
            setLoading(true);
            toggleState('hidden');
          }}
        >
          {t('shared.yesThatsOk')}
        </ButtonSubmit>
      </Modal.Actions>
    </Modal>
  );
};

export default ArchiveGroupModal;
