import { DefaultProfilePic, SmilingFaceWithSunglasses } from '@flipgrid/flipkit';
import { useEffect, useState } from 'react';

import { addUrlParams } from '~/helper/addUrlParams';
import profilePictureStyles from '~/styles/components/Utility/components/ProfilePicture.css';

import type { User } from 'types';

export const links = () => [{ rel: 'stylesheet', href: profilePictureStyles }];

type Props = {
  className?: string;
  size?: 'xsmall' | 'small' | 'medium';
  id?: number;
  image_url?: string;
  user?: User;
};

const ProfilePicture = ({ className = '', id, image_url, size = 'xsmall', user }: Props) => {
  const [defaultId, setDefaultId] = useState(id);
  const [imageUrl, setImageUrl] = useState(image_url);

  useEffect(() => {
    if (!user) {
      setDefaultId(id);
      setImageUrl(image_url);
    }
  }, [user, image_url, id]);

  if (user) {
    setDefaultId((prev) => user.id || prev);
    setImageUrl((prev) => user.image_url || prev);
  }

  if (imageUrl) {
    return (
      <img
        src={addUrlParams({ size }, imageUrl)}
        className={'profilePicture background__bg-1 profilePicture-image ' + className}
        alt=""
        loading="lazy" />);


  }

  if (defaultId) return <DefaultProfilePic className={'profilePicture ' + className} id={defaultId} />;

  return <SmilingFaceWithSunglasses className={'profilePicture ' + className} />;
};

export default ProfilePicture;