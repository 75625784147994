import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { useRef, useState, useEffect, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import type { Placement } from '@popperjs/core';
import type { RefObject } from 'react';

type PopoverProps = {
  children: React.ReactNode;
  className?: string;
  childClassName?: string;
  id: string;
  isVisible: boolean;
  onDismiss?: () => void;
  size?: 'small' | 'default';
  placement?: Placement;
  targetRef: RefObject<HTMLElement>;
  useArrow?: boolean;
  useFocusTrap?: boolean;
  usePortal?: boolean;
  role?: string;
};

/*
  TODO:  Fix - When using Focus Trap, if you open the Popover, then scroll,
  then click outside the Popover, you are scrolled back to the Popover before it closes.
  Also handle Focus Trap when there is no focusable element.

  If the targetRef is hidden at certain break points via css, do not use Portal
  unless also hiding the Popover via css at same breakpoints, since it cannot be
  positioned without it's target element visible.
*/

const PopoverInner = ({
  children,
  className,
  childClassName,
  id,
  isVisible,
  onDismiss,
  size = 'default',
  placement = 'auto',
  targetRef,
  useArrow = true,
  useFocusTrap,
  role,
}: PopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  // Need to use a callback Ref here
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const isSmall = size === 'small';

  // Close Popover if escaped
  const escapeListener = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (isVisible && onDismiss) {
        onDismiss();
        if (targetRef.current) targetRef.current.focus();
      }
    }
  };

  // Close if clicked outside of Popover
  const clickListener = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const isEventTargetOutsideCallout = popoverRef.current && !popoverRef.current.contains(target);

    if (targetRef.current && isEventTargetOutsideCallout) {
      if (isVisible && onDismiss) onDismiss();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      if (isVisible && onDismiss) onDismiss();
    }
  };

  const offset = useArrow ? 12 : 8;

  // Attach and cleanup event listeners
  useEffect(() => {
    document.addEventListener('mousedown', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('mousedown', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  });

  // The usePopper hook, see popper docs for option details
  const { styles, attributes } = usePopper(targetRef.current, popoverRef.current, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, offset],
        },
      },
    ],
  });

  if (!isVisible) return null;

  return (
    <div
      id={id}
      className={classNames(['fk-popover', className, { 'is-small': isSmall }])}
      ref={popoverRef}
      onBlur={handleBlur}
      role={role || 'tooltip'}
      style={styles.popper}
      {...attributes.popper}
    >
      <div className={classNames(['fk-popover__children', childClassName])}>
        {useFocusTrap ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true,
            }}
          >
            <div>{children}</div>
          </FocusTrap>
        ) : (
          <Fragment>{children}</Fragment>
        )}
      </div>
      <div ref={setArrowRef} style={styles.arrow} className={classNames('fk-popover__arrow', { '-hide': !useArrow })} />
    </div>
  );
};

const Popover = ({ usePortal, ...rest }: PopoverProps) => {
  // Create container element for Portal
  const [container, setContainer] = React.useState<HTMLDivElement>();

  useEffect(() => {
    if (!container) setContainer(document.createElement('div'));

    if (container) document.body.appendChild(container);
    return () => {
      if (container) document.body.removeChild(container);
    };
  }, [container, setContainer]);

  // Portal to optionally avoid css cascade issues
  if (usePortal && container) return createPortal(<PopoverInner {...rest} />, container);

  return <PopoverInner {...rest} />;
};

export default Popover;
