import { Button, ButtonSubmit, IcFluentRestore24Regular } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { debounce, has } from 'lodash-es';
import { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CopyButtonTooltip from './CopyButtonTooltip';
import VanityTokenStatus, { links as vanityTokenStatusStyles } from './VanityTokenStatus';
import Input from '../FkWrappers/Input';
import resourceRoutes from '~/constants/resourceRoutes';
import GlobalContext from '~/contexts/globalContext';
import shareGroupModalContext from '~/contexts/shareGroupModalContext';
import copyShareLinkStyles from '~/styles/components/Share/CopyShareLink.css';

import type { FormEvent } from 'react';
import type { ApiResponse, Group } from 'types';

export const links = () => [...vanityTokenStatusStyles(), { rel: 'stylesheet', href: copyShareLinkStyles }];

type Props = {
  groupId?: number;
  includeLeftButton?: boolean;
  isEditMode?: boolean;
  isStudentGroup: boolean;
  label: string;
  leftButtonTheme?: 'primary' | 'muted' | 'secondary'; // todo: add flipkit type when available
  onTokenUpdate?: (updatedToken: string) => void;
  rootPath: string;
  shouldShowRefreshBtn?: boolean;
} & (
{
  shareToken: string;
  vanityToken?: never;
} |
{
  shareToken?: never;
  vanityToken: string;
});


const CopyShareLink = ({
  groupId,
  includeLeftButton,
  isEditMode = false,
  isStudentGroup,
  label,
  leftButtonTheme = 'primary',
  onTokenUpdate,
  rootPath,
  shareToken,
  shouldShowRefreshBtn = false,
  vanityToken
}: Props) => {
  const { vanityToken: currentVanityToken, setVanityToken: setCurrentVanityToken } = useContext(shareGroupModalContext);
  const { announceLiveMessage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [copiedURL, setCopiedURL] = useState(false);
  const [shouldShowSuccessMsg, setShouldShowSuccessMsg] = useState(false);
  const updateVanityTokenFetcher = useFetcher<ApiResponse<Group>>();
  const validationFetcher = useFetcher<{
    data: {
      error: boolean;
      invalidLength?: boolean;
      isCurrentToken: boolean;
      isEmpty: boolean;
      specialCharacters?: boolean;
      valid: boolean;
    };
  }>();
  const hasVanityTokenUpdated =
  updateVanityTokenFetcher.type === 'done' && Object.keys(updateVanityTokenFetcher.data?.data ?? {}).length > 3;

  const validateToken = useCallback(
    (e: TSFix) => {
      if (currentVanityToken) {
        const formData = new FormData();
        formData.append('_action', 'vanity_token');
        formData.append('current_vanity_token', currentVanityToken);
        formData.append('vanity_token', e.target.value.toLowerCase()); // vanity tokens must be lower case
        validationFetcher.submit(formData, { method: 'post', action: resourceRoutes.validators });
      }
    },
    [currentVanityToken, validationFetcher]
  );

  const debouncedChangeHandler = useMemo(() => debounce(validateToken, 150), [validateToken]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const resetCopyDelay = setTimeout(() => {
      setCopiedURL(false);
    }, 925);
    return () => {
      clearTimeout(resetCopyDelay);
    };
  }, [copiedURL, debouncedChangeHandler]);

  useEffect(() => {
    const resetFetcher = () => {
      updateVanityTokenFetcher.data = undefined;
    };

    if (hasVanityTokenUpdated && onTokenUpdate) {
      setShouldShowSuccessMsg(true);
      onTokenUpdate(updateVanityTokenFetcher.data?.data.vanity_token);
      resetFetcher();
    }
  }, [hasVanityTokenUpdated, onTokenUpdate, updateVanityTokenFetcher]);

  useEffect(() => {
    const updatedToken = updateVanityTokenFetcher.data?.data.vanity_token;
    const isNewToken = updatedToken !== currentVanityToken;

    // update vanity_token state when the response is updated
    if (updatedToken) {
      const editModeInput: HTMLInputElement | null = document.querySelector('#copy-share-link-edit');
      if (editModeInput && isNewToken) {
        editModeInput.value = updatedToken;
      }
      setCurrentVanityToken(updatedToken);
    }
  }, [currentVanityToken, setCurrentVanityToken, updateVanityTokenFetcher.data?.data.vanity_token]);

  const labelValue = useMemo(() => {
    if (rootPath && rootPath.includes('https://')) return rootPath.slice(8, rootPath.length);
    return rootPath;
  }, [rootPath]);

  const resetValidationFetcher = () => {
    updateVanityTokenFetcher.data = undefined;
  };

  const copy = () => {
    const copyValue = 'https://' + getShareToken();
    navigator.clipboard.writeText(copyValue);

    announceLiveMessage(t('shared.copiedToClipboard'));
    setCopiedURL(true);
  };

  const getShareToken = () => {
    const isRequestingToJoin = !currentVanityToken && !vanityToken && !shareToken;

    if (typeof window !== 'undefined' && isRequestingToJoin) {
      const urlShareToken = window.location.pathname.split('/')[1];
      return labelValue + urlShareToken;
    }
    return labelValue + (currentVanityToken || shareToken || vanityToken);
  };

  const submitToken = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShouldShowSuccessMsg(false);

    // prevent click spamming
    if (updateVanityTokenFetcher.state !== 'idle') return;

    const isFormSubmission = e.type === 'submit';
    const formData = isFormSubmission ? e.currentTarget : new FormData();

    // if there is no form data, create the form data to reset the token
    if (!isFormSubmission && groupId) {
      formData.append('_action', 'updateGroup');
      formData.append('vanity_token', '');
      formData.append('id', groupId.toString());
    }

    const params = new URLSearchParams({ manage: 'true' });
    updateVanityTokenFetcher.submit(formData, {
      method: 'post',
      action: `${resourceRoutes.updateState}?${params.toString()}`
    });
  };

  const getTokenErrors = () => {
    const isInvalidCharacters = validationFetcher.data?.data.specialCharacters;
    const isInvalidLength = validationFetcher.data?.data.invalidLength;
    const isDuplicateToken =
    typeof validationFetcher.data?.data.valid === 'boolean' && !validationFetcher.data?.data.valid; // only time error is true is when the token is a duplicate, or is an inappropriate word
    const isInappropriateToken = !!(
    isDuplicateToken &&
    has(validationFetcher, 'data.error.uid') &&
    (validationFetcher.data as TSFix).error.uid.find((responseErr: string[]) => responseErr.includes('inappropriate')));


    if (isInvalidCharacters || isInvalidLength || isDuplicateToken || isInappropriateToken) {
      return { isDuplicateToken, isInvalidLength, isInvalidCharacters, isInappropriateToken };
    }

    return null;
  };

  if (isEditMode) {
    return (
      <>
        <div className="copyShareLink">
          <validationFetcher.Form
            onChange={(e) => {
              if (hasVanityTokenUpdated) resetValidationFetcher();
              debouncedChangeHandler(e);
            }}
            onSubmit={submitToken}
            method="post">

            <Input
              innerWrapperClassName="copyShareLink__inputBackground"
              label={label}
              defaultValue={currentVanityToken}
              inputClassName="copyShareLink__editTokenInput"
              type="text"
              name="vanity_token"
              id="copy-share-link-edit"
              data-testid="copyShareLink__input__copyToClipboard"
              aria-label={t('copyShareLink.copyToClipboard')}
              prefix={rootPath}
              inputButton={
              <ButtonSubmit
                className="copyShareLink__saveTokenBtn"
                loading={
                updateVanityTokenFetcher.type === 'actionReload' ||
                updateVanityTokenFetcher.type === 'actionSubmission'}

                data-testid="copyShareLink__button__saveVanityRoute"
                size="36"
                disabled={!!getTokenErrors()}>

                  {t('common.save')}
                </ButtonSubmit>}

              maxLength={32}
              floatingLabel
              key="editVanityToken" // needed for React to recognize input swap
            />
            <input type="hidden" name="_action" value="updateGroup" />
            <input type="hidden" name="id" value={groupId} />
          </validationFetcher.Form>
        </div>

        {!isStudentGroup && shouldShowRefreshBtn &&
        <VanityTokenStatus
          getTokenErrors={getTokenErrors}
          hasVanityTokenUpdated={hasVanityTokenUpdated}
          isEditMode={isEditMode}
          submitToken={submitToken}
          validationState={validationFetcher.state} />}


      </>);

  }

  return (
    <div className="copyShareLink">
      <Input
        label={label}
        labelClassName={`copyShareLink__label ${includeLeftButton && '-hasLeftButton'} fk-fontWeight__bold`}
        value={getShareToken()}
        wrapperClassName="w100"
        type="text"
        name="copyShareLink"
        data-testid="copyShareLink__input__copyToClipboard"
        innerWrapperClassName={`copyShareLink__inputWrapper${shouldShowSuccessMsg ? '--success' : ''}`}
        aria-label={t('copyShareLink.copyToClipboard')}
        helpTextBottom={shouldShowSuccessMsg ? t('shared.inviteLinkUpdated') : undefined}
        helpTextBottomClassName="color__success"
        inputButtonLeft={
        includeLeftButton &&
        <Button
          onClick={copy}
          aria-label={t('shared.copyLinkUrl', { url: getShareToken() })}
          theme={leftButtonTheme}
          icon={<IcFluentRestore24Regular className="iconFill__fg-1" width="16" height="16" />}
          size="36"
          data-testid="copyShareLink__button__copyToClipboardBtn"
          className="copyShareLink__copyBtn" />}



        inputButton={
        <CopyButtonTooltip text={getShareToken()} onCopied={copy} copiedURL={copiedURL} />}


        disabled
        floatingLabel />

    </div>);

};

export default CopyShareLink;