export const setActiveState = (
  value: string,
): {
  locked: boolean;
  active: boolean;
} => {
  switch (value) {
    case 'viewOnly':
      return { locked: true, active: true };
    case 'visible':
      return { locked: false, active: true };
    case 'hidden':
      return { locked: false, active: false };
    default:
      return { locked: true, active: true };
  }
};
