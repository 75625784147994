import {
  Dropdown,
  IcFluentArchive24Regular,
  IcFluentCheckmarkCircle24Regular,
  IcFluentEyeHide24Filled,
  IcFluentProhibited24Regular,
  ModalConsumer,
} from '@flipgrid/flipkit';
import { useSelector } from '@xstate/react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { setActiveState } from '../../helper/topicFormHelpers/setActiveState';
import ConfirmActivateResponseModal from '../Modals/ActivateResponseStatusModal';
import BulkHideResponsesModal from '../Modals/BatchActions/BulkHideResponsesModal';
import ArchiveGroupModal from '../Modals/GroupDetails/ArchiveGroupModal';
import ConfirmViewOnlyModal from '../Modals/TopicList/ConfirmViewOnlyTopicModal';
import resourceRoutes from '~/constants/resourceRoutes';
import GlobalContext from '~/contexts/globalContext';
import { isFlagPresent } from '~/helper/helper';
import { hasTopicEnded } from '~/helper/topicDatesHelpers';
import useGetUser from '~/hooks/useGetUser';
import { useGroupsStateMachine } from '~/statemachine/GroupsStateMachineContext';

import type { FetcherWithComponents } from '@remix-run/react';
import type { FlipResponse, Group, Mixtape, Topic } from 'types';

export type Status = 'hidden' | 'viewOnly' | 'visible';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateStateFetcher: FetcherWithComponents<any>;
} & (
  | {
      type: 'Response';
      item: FlipResponse;
    }
  | {
      type: 'Topic';
      item: Topic;
    }
  | {
      type: 'Group';
      item: Group;
    }
  | {
      type: 'Mixtape';
      item: Mixtape;
    }
);

const ActiveStateDropdown = ({ item, type, updateStateFetcher }: Props) => {
  const { t } = useTranslation();
  const user = useGetUser();

  const { announceLiveMessage } = useContext(GlobalContext);

  const groupStateSelector = (state: { matches: (arg0: string) => boolean }) =>
    state.matches('removeGroupOnNextNavigation');
  const groupStateMachine = useGroupsStateMachine();
  const { send } = groupStateMachine.groupsService;
  const hasGroupIDToRemove = useSelector(groupStateMachine.groupsService, groupStateSelector);

  const { featureFlags } = useContext(GlobalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');
  
  const typeProps = useMemo(() => {
    switch (type) {
      case 'Response':
        return {
          activeSelected: t('activeStateDropdown.activeSelectedResponse'),
          activeTooltip: t('activeStateDropdown.everyoneResponse'),
          activateText: t('shared.activateResponse'),
          hiddenSelected: t('activeStateDropdown.hiddenSelectedResponse'),
          hiddenTooltip: t('activeStateDropdown.noOneResponse'),
          hideText: t('shared.hideResponse'),
        };
      case 'Topic':
        return {
          activeSelected: t('activeStateDropdown.activeSelectedTopic'),
          activeTooltip: t('activeStateDropdown.everyoneRespondTopic'),
          activateText: t('shared.activateTopic'),
          hiddenSelected: t('activeStateDropdown.hiddenSelectedTopic'),
          hiddenTooltip: t('activeStateDropdown.noOneTopic'),
          hideText: t('shared.hideTopic'),
          name: item.title,
          viewOnlyText: t('shared.viewOnlyTopic'),
          viewOnlySelected: t('activeStateDropdown.viewOnlySelectedTopic'),
          viewOnlyTooltip: t('activeStateDropdown.everyoneViewTopic'),
        };
      case 'Group':
        return {
          activeSelected: t('activeStateDropdown.activeSelectedGroup'),
          activeTooltip: t('activeStateDropdown.everyoneGroup'),
          activateText: t('shared.activateGroup'),
          hiddenSelected: t('activeStateDropdown.hiddenSelectedGroup'),
          hiddenTooltip: t('activeStateDropdown.noOneGroup'),
          hideText: t('shared.archiveGroup'),
          name: item.name,
        };
      case 'Mixtape':
        return {
          activeSelected: t('activeStateDropdown.activeSelectedMixtape'),
          activeTooltip: t('activeStateDropdown.everyoneMixtape'),
          activateText: t('activeStateDropdown.activateMixtape'),
          hiddenSelected: t('activeStateDropdown.hiddenSelectedMixtape'),
          hiddenTooltip: t('activeStateDropdown.noOneMixtape'),
          hideText: t('activeStateDropdown.hideMixtape'),
          privateTooltip: t('activeStateDropdown.passwordMixtape'),
        };
    }
  }, [type, item, t]);

  const toggleState = (value: Status) => {
    const formData = new FormData();
    formData.append('id', item.id.toString());

    if ((type === 'Topic' || type === 'Response') && item.grid_id) {
      formData.append('grid_id', item.grid_id.toString());
    }

    const { active, locked } = setActiveState(value);
    if (type === 'Group') {
      if (active) {
        if (hasGroupIDToRemove) send('SUCCESS');
        //  need to update with the more recent updated at time
        else if (!item.id) send('ADD', { group: { ...item, updated_at: new Date() } });
      } else if (item.id) {
        send('REMOVE_NEXT_NAVIGATION', { groupID: item.id });
      } else send('REMOVE', { group: item });
    }
    formData.append('_action', 'update' + type);
    formData.append('active', active.toString());
    formData.append('locked', locked.toString());

    if (type === 'Topic' && value === 'visible' && hasTopicEnded(item.lock_at, user?.timezone)) {
      formData.append('fieldsToParse', JSON.stringify(['lock_at']));
      formData.append('lock_at', 'null');
    }

    switch (value) {
      case 'viewOnly':
        announceLiveMessage(typeProps.viewOnlySelected);
        break;
      case 'visible':
        announceLiveMessage(typeProps.activeSelected);
        break;
      case 'hidden':
        announceLiveMessage(typeProps.hiddenSelected);
        break;
      default:
        break;
    }

    updateStateFetcher.submit(formData, {
      method: 'post',
      action: type === 'Response' ? `${resourceRoutes.updateState}?manage=true` : resourceRoutes.updateState,
    });
  };

  const makeVisible = () => {
    toggleState('visible');
  };

  const hide = () => {
    toggleState('hidden');
  };

  const makeViewOnly = () => {
    toggleState('viewOnly');
  };

  const onArchival = (archivedGroup: Group) => {
    send('REMOVE', { group: archivedGroup });
  };

  const dropdownItems = (
    <ModalConsumer>
      {({ showModal }) => (
        <>
          {(!item.active || (type === 'Topic' && item.locked)) && (
            <Dropdown.Item
              data-testid="activeStateDropdown__button__active"
              icon={<IcFluentCheckmarkCircle24Regular />}
              onSelect={
                type === 'Response'
                  ? () =>
                      showModal(ConfirmActivateResponseModal, {
                        onContinue: makeVisible,
                      })
                  : makeVisible
              }
            >
              {typeProps.activateText}
            </Dropdown.Item>
          )}

          {type === 'Topic' && !item.locked && (
            <Dropdown.Item
              data-testid="activeStateDropdown__button__viewOnly"
              icon={<IcFluentEyeHide24Filled />}
              onSelect={() =>
                showModal(ConfirmViewOnlyModal, {
                  onContinue: makeViewOnly,
                })
              }
            >
              {typeProps.viewOnlyText}
            </Dropdown.Item>
          )}

          {isDeprecated
            ? null
            : item.active && (
                <Dropdown.Item
                  data-testid="activeStateDropdown__button__hidden"
                  icon={type === 'Group' ? <IcFluentArchive24Regular /> : <IcFluentProhibited24Regular />}
                  onSelect={
                    type === 'Group'
                      ? () => showModal(ArchiveGroupModal, { toggleState, onArchival })
                      : type === 'Response'
                        ? () =>
                            showModal(BulkHideResponsesModal, {
                              batchUpdateResponses: hide,
                              numResponses: 1,
                            })
                        : hide
                  }
                >
                  {typeProps.hideText}
                </Dropdown.Item>
              )}
        </>
      )}
    </ModalConsumer>
  );

  return dropdownItems;
};

export default ActiveStateDropdown;
