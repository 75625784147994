import { Modal, Button, ButtonSubmit } from '@flipgrid/flipkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import useFetcherWatcher from '~/hooks/useFetcherWatcher';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ResourceRouteType } from 'types';

type Props = {
  batchUpdateResponses: () => void;
  numResponses: number;
  onRequestClose: OnRequestCloseType;
};

const BulkHideResponsesModal = ({ batchUpdateResponses, numResponses, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  // Watch for fetcher completion
  useFetcherWatcher({
    action: `${resourceRoutes.updateState}?manage=true` as ResourceRouteType,
    formDataAction: 'updateResponses',
    update: () => {
      onRequestClose(t('bulkHideResponsesModal.successful'));
      setIsLoading(false);
    },
  });

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('bulkHideResponsesModal.hideVideoResponse', { count: numResponses })}</h1>
      <p className="fk-modalBody m0">{t('bulkHideResponsesModal.hidingResponse', { count: numResponses })}</p>
      <Modal.Actions className="mt2">
        <Button
          onClick={onRequestClose}
          disabled={isLoading}
          data-testid="bulkHideResponsesModal__button__cancel"
          theme="secondary"
        >
          {t('common.cancel')}
        </Button>
        <ButtonSubmit
          onClick={() => {
            setIsLoading(true);
            batchUpdateResponses();
          }}
          data-testid="bulkHideResponsesModal__button__submit"
          loading={isLoading}
        >
          {t('common.hide')}
        </ButtonSubmit>
      </Modal.Actions>
    </Modal>
  );
};

export default BulkHideResponsesModal;
