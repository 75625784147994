import { Button, IcFluentQrCode24Regular } from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import { has } from 'lodash-es';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popover from '../FkWrappers/Popover';
import MakeQR from '../Utility/MakeQR';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import shareQRCodeBtnStyles from '~/styles/components/Share/ShareQRCodeBtn.css';

import type { actionSizes, themesForAllVariants, themesForNonTextVariants } from '@flipgrid/flipkit';
import type { DisplayGroup, Group, ShareEntity } from 'types';

export const links = () => [{ rel: 'stylesheet', href: shareQRCodeBtnStyles }];

type Props = {
  btnClassName?: string;
  btnSize?: actionSizes;
  btnTheme?: themesForAllVariants | themesForNonTextVariants;
  entity:
  ShareEntity |
  {
    type: 'Group';
    item: Group | DisplayGroup;
  };
  onShareTokenRetrieval?: (shareToken: string) => void;
  token: string;
};

const ShareQRCodeBtn = ({
  btnClassName,
  btnSize = '36',
  btnTheme = 'transparent',
  entity,
  onShareTokenRetrieval,
  token
}: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const buttonRef = useRef(null);
  const { setOpenPopover } = useContext(globalContext);
  const [routeData] = useMatches();
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const [shareToken, setShareToken] = useState('');
  const [hasLoadedShareToken, setHasLoadedShareToken] = useState(false);
  const shareBaseUrl = routeData.data.env.CLIENT_SHARE_URL;
  const shouldHandlePrivateToken =
  entity.type === 'Response' || entity.type === 'Comment' || entity.type === 'MyVideos';

  // Generate private share token
  const generatePrivateShareToken = () => {
    const formData = new FormData();
    formData.append('responseId', entity.item.id.toString());
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.shareToken });
  };

  useEffect(() => {
    setOpenPopover(isQRCodeVisible);
  }, [isQRCodeVisible, setOpenPopover]);

  useEffect(() => {
    if (shouldHandlePrivateToken && fetcher.state === 'idle') generatePrivateShareToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHandlePrivateToken]);

  // Load private share token
  useEffect(() => {
    if (!hasLoadedShareToken && shouldHandlePrivateToken && fetcher.state === 'idle') {
      setHasLoadedShareToken(true);

      const qs = new URLSearchParams([['responseId', entity.item.id.toString()]]);
      fetcher.load(`${resourceRoutes.shareToken}?${qs}`);
    }
  }, [entity.item.id, entity.type, fetcher, hasLoadedShareToken, shouldHandlePrivateToken]);

  // Set private share token
  useEffect(() => {
    const resetFetcher = () => {
      fetcher.data = undefined;
    };

    const hasTokenResponse = has(fetcher, 'data.data.token');
    if (hasTokenResponse) {
      const privateShareToken = fetcher.data?.data.token;
      if (onShareTokenRetrieval) onShareTokenRetrieval(privateShareToken);
      setShareToken(privateShareToken);
      resetFetcher();
    }
  }, [fetcher, onShareTokenRetrieval]);

  const getGroupToken = () => {
    if (!token) {
      return window.location.pathname.split('/')[1];
    }
    return token;
  };

  return (
    <>
      <Button
        ref={buttonRef}
        className={btnClassName}
        aria-label={t('shareQrCodeBtn.displayQRCode')}
        aria-describedby={isQRCodeVisible ? 'share-modal-popover' : null}
        data-testid="shareModal__button__qrCode"
        onClick={() => setIsQRCodeVisible((prev) => !prev)}
        theme={btnTheme}
        size={btnSize}
        icon={<IcFluentQrCode24Regular width="24" height="24" />}
        disabled={fetcher.state !== 'idle'} />

      <Popover
        targetRef={buttonRef}
        onDismiss={() => setIsQRCodeVisible(false)}
        id="share-modal-popover"
        isVisible={isQRCodeVisible}
        placement="bottom">

        <div className="shareQrCodeBtn__qrCode">
          {entity.type === 'Group' || entity.type === 'Topic' ?
          <MakeQR allowsAr={false} url={`${shareBaseUrl}/${getGroupToken()}`} token={getGroupToken()} /> :
          entity.type === 'Mixtape' ?
          <MakeQR token={token} url={routes.MIXTAPES_ID_URL_FUNC(token, shareBaseUrl)} name={entity.item.title} /> :

          <MakeQR
            allowsAr
            token={shareToken}
            url={`${shareBaseUrl}/s/${shareToken}`}
            name={entity.item.display_name} />}


        </div>
      </Popover>
    </>);

};

export default ShareQRCodeBtn;